import localazyMetadata from '@/assets/i18n/meta'
import { type LocaleKey } from '@/assets/i18n'
import { locales } from '@/assets/i18n'

/*
 *NOTE - getLocaleFromCDN is a function that fetches the locale from the Localazy CDN.
 * It uses the metadata to find the correct uri for the locale.
 * The locale is then fetched from the CDN and assigned to the locales object.
 * The locales object is then used to provide the translations to the app.
 * If no metadata is provided, the function uses the default metadata.
 * If no translations are found, the built-in fallbacks are used.
 */

async function getLocaleFromCDN(locale: LocaleKey, metadata: any | null = null) {
    /*  NOTE - If the environment is development, no need to fetch the locale from the CDN.
     * To get the latest translations use the Localazy CLI to pull the latest translations using: localazy download
     */
    if (import.meta.env.VITE_ENVIRONMENT === 'dev') return
    if (metadata) {
        const _file: any = Object.values(metadata.files).find((file: any) => file.file === 'webapp.json')

        if (!_file) {
            throw new Error('File not found')
        }

        // Find the latest cdn uri for the locale that we want to update
        const uri = _file.locales.find((obj: any) => obj.language === locale).uri
        // Get the locale from the CDN
        const res = await fetch(`https://delivery.localazy.com${uri}`)
        // Unpack the locale (This is a JSON object with the locale keys and values)
        const obj = await res.json()

        //If the locale is not found, return
        if (!obj) return

        // Assign the locale to the locales object (see src/assets/i18n/index.ts)
        locales.value[locale] = Object.assign({}, locales.value[locale], obj)
    }
    if (!metadata) {
        const _file = localazyMetadata.files.find((file) => file.file === 'webapp.json')

        if (!_file) {
            throw new Error('File not found')
        }

        const uri = _file.cdnFiles[locale]
        const res = await fetch(uri)
        const obj = await res.json()

        if (!obj) return
        /*
         * Overwrite only those keys that are present in the CDN.
         * This is to ensure that the fallbacks are not overwritten.
         */
        locales.value[locale] = Object.assign({}, locales.value[locale], obj)
    }
}

export default {
    getLocaleFromCDN,
}
