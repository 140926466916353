<script setup lang="ts">
    import { watch, nextTick } from 'vue'
    import { useRoute } from 'vue-router'
    import { useTheCrudDrawer } from './TheCrudDrawer'

    import shortCutMap from '@/utilities/shortcuts/shortCutMap'
    import HintWidget from '@/utilities/shortcuts/hintWidget.vue'

    import { useI18n } from 'vue-i18n'
    const { t } = useI18n()

    const { esc } = shortCutMap

    const crudDrawer = useTheCrudDrawer()

    const { type, drawer, asyncCrud, crudRef, dialog, switchToCreate, switchToUpdate, toggleCQCreate, promptClose } = crudDrawer

    esc(promptClose, 'drawer')

    function focusFirstInput() {
        const elem = document.querySelector(':is(#the-crud-drawer, .crud-drawer-dialog) input')
        if (!elem || !(elem instanceof HTMLInputElement)) return
        elem.focus()
    }

    watch([dialog, drawer], ([dialog, drawer]) => {
        if (!dialog && !drawer) return
        setTimeout(focusFirstInput, 10)
    })

    const route = useRoute()

    watch(
        () => route.query,
        async (query) => {
            await new Promise((resolve) => setTimeout(resolve, 1000))
            if (query?.action === 'create') {
                switchToCreate({})
            } else if (query?.action === 'update') {
                const id = route.params.id
                const toNumber = parseInt(id as string)
                await nextTick()
                switchToUpdate(toNumber)
            }
        },
        { immediate: true }
    )
</script>

<template>
    <v-navigation-drawer location="right" temporary id="the-crud-drawer" class="crud-drawer" v-model="drawer">
        <v-btn class="crud-drawer__close" @click.prevent="promptClose"
            ><hint-widget shift-key keyPress="W" color="neutral" context="either" />{{ t('generalKeys.close') }}<i class="fa-sharp fa-solid fa-xmark"></i
        ></v-btn>
        <component
            :is="asyncCrud"
            v-if="asyncCrud"
            ref="crudRef"
            v-model:showDialog="dialog"
            view="both"
            :type="type"
            @qcToCreate="toggleCQCreate"
            @cancel="
                () => {
                    dialog = false
                    drawer = false
                }
            "
            @close="
                () => {
                    dialog = false
                    drawer = false
                }
            "
        />
    </v-navigation-drawer>
</template>

<style lang="scss" scoped>
    // media width css
    @media (max-width: 720px) {
        #the-crud-drawer {
            min-width: 90dvw;
        }
    }

    @media (max-width: 600px) {
        .crud-drawer__close {
            display: flex;
            margin-bottom: 1rem;
        }
    }
    @media (min-width: 600px) {
        .crud-drawer__close {
            position: absolute;
            right: calc(100% + 0.5rem);
            rotate: -90deg;
            transform-origin: 100% 100%;
            display: flex;
            gap: 0.5rem;
        }
    }
    .crud-drawer {
        min-width: 60dvw;
    }

    /* make a content query here */
</style>
