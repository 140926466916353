<script setup lang="ts">
    import { ref, computed } from 'vue'
    import { onClickOutside } from '@vueuse/core'
    import NotificationComponent from '@/components/notification/NotificationComponent.vue'
    import { useAuthStore } from '@stores/authStore'
    const authStore = useAuthStore()

    const drawerRef = ref<HTMLElement | null>(null)

    const onClickAway = () => {
        authStore.notificationPanel.navBtn = true
        if (authStore.notificationPanel.show && authStore.notificationPanel.navBtn) {
            authStore.notificationPanel.show = false
            authStore.notificationPanel.navBtn = false
        }
    }
    onClickOutside(drawerRef, onClickAway)

    const togelPanel = computed(() => {
        return authStore.notificationPanel.show
    })
</script>

<template>
    <!-- the notification right -->
    <v-navigation-drawer location="right" width="305" temporary v-model="authStore.notificationPanel.show" theme="dark" color="blue-grey-darken-3" class="d-print-none">
        <notification-component ref="drawerRef" :panel="togelPanel" />
    </v-navigation-drawer>
</template>

<style lang="scss" scoped>
    .wrapper {
        display: grid;
        grid-template-columns: repeat(2, auto) minmax(0, 1fr);
        gap: 1rem;
        height: 100dvh;
    }
    :deep(.v-navigation-drawer__content) {
        padding: 0 !important;
        width: 100% !important;
        flex: none !important;
        background-color: #37474f !important;
    }
</style>
