import { FormKitNode } from '@formkit/core'
import { useToast } from 'vue-toastification'
import { useGlobalt } from '@/assets/i18n/globalt'

const { globalt: t } = useGlobalt()
const toast = useToast()

function throwError(error: any) {
    throw error
}

export async function apiCallWrap(fn: () => Promise<any>, node?: FormKitNode, finalFn?: () => void, successMsg?: string) {
    if (node && node.clearErrors) {
        if (!node.clearErrors) {
            console.log({ node })
            return
        }
        node.clearErrors()
    }
    let _isSuccess = true
    return fn()
        .catch((error) => {
            _isSuccess = false
            return handleError(error, node) || throwError(error)
        })
        .finally(() => {
            finalFn && finalFn()
            node ? (node.props.disabled = false) : null
            if (_isSuccess && successMsg) {
                toast.success(t.value(successMsg), { timeout: 3000 })
            }
        })
}

export function handleError(error: unknown, node?: FormKitNode) {
    let _msg: string
    if (typeof error === 'string') {
        _msg = error
    } else if (error instanceof Error || (typeof error === 'object' && error !== null)) {
        _msg = handleObjectError(error)
    }

    _msg ??= 'generalResponse.codeServerError'

    if (node) {
        node.setErrors([t.value(_msg)])
        //return
    } else {
        const timeout = biggestNumber(wordCount(t.value(_msg)) * 500, 5000)
        toast.error(t.value(_msg), { timeout })
    }

    if (_msg === 'generalResponse.codeServerError') {
        return false
    }
    return true
}

// statement to return the biggest number
function biggestNumber(a: number, b: number) {
    return a > b ? a : b
}

//give me a function takes a string and returns me the wordcount
function wordCount(str: string) {
    return str.split(' ').length
}

function isTranslated(str: string) {
    return t.value(str) !== str
}

function handleObjectError(error: Record<string, any>) {
    // all Xano errors have auto prefix 'api.'
    if (error.message && isTranslated('api.' + error.message)) {
        return 'api.' + error.message
    }

    if (error.message && isTranslated(error.message)) {
        return error.message
    }

    if (error.code && isTranslated(error.code)) {
        return error.code
    }

    return 'generalResponse.codeServerError'
}
