import { ref, Ref } from 'vue'

interface Route {
    name: string
    params?: Record<string, string | string[]> | undefined
    query?: Record<string, (string | null) | (string | null)[]> | undefined
}

const initialLoad = ref(true)
const initialRoute: Ref<Route | undefined> = ref()

export { initialLoad, initialRoute }
