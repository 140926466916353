<script setup lang="ts">
    import { computed, onMounted, onUnmounted } from 'vue'
    import { useI18n } from 'vue-i18n'
    import dateUtils from '@/utilities/dateUtils'
    import { storeToRefs } from 'pinia'
    const { t } = useI18n()

    //Router
    import { useRouter } from 'vue-router'
    const router = useRouter()

    import { useNotificationStore } from '@/stores/notificationStore'
    const store = useNotificationStore()
    const { notifications } = storeToRefs(store)

    import { useCommunicationStore } from '@/stores/communicationStore'
    const commStore = useCommunicationStore()
    const { communications } = storeToRefs(commStore)

    import { useTaskStore } from '@/stores/taskStore'
    const taskStore = useTaskStore()
    const { task } = storeToRefs(taskStore)

    import { useAuthStore } from '@stores/authStore'
    const authStore = useAuthStore()

    import { _crudMap } from '@/components/crud/crudManager'
    import { useTheCrudDrawer } from '@/components/TheCrudDrawer'
    const crudDrawer = useTheCrudDrawer()

    import { TypesMap } from '@/components/crud/crudManager'
    import { nextTick, ref } from 'vue'
    const { type, switchToRead } = crudDrawer
    type.value = 'communication'

    const notificationInterval = ref()

    onMounted(() => {
        store.getNotifications()
        notificationInterval.value = setInterval(() => {
            store.getNotifications()
        }, 180 * 1000) // every 3 minutes
    })

    onUnmounted(() => {
        if (notificationInterval.value) {
            clearInterval(notificationInterval.value)
        }
    })

    function handleRead(item: any) {
        if (item.readNotification === false || item.readNotification === null) {
            item.readNotification = true
            store.updateNotification(item, item.id)
        }
    }

    async function handleReadAll() {
        store.allReadNotification()
    }

    async function openNotification(item: any) {
        let theType: keyof TypesMap = 'communication'
        let theId: number = 0
        if (item.notificationType === 'Task' && item.task_id !== 0 && item.communication_id === 0) {
            theType = 'task'
            theId = item.task_id
        } else if (item.notificationType === 'Task' && item.communication_id !== 0) {
            theType = 'communication'
            theId = item.communication_id
        } else if (item.notificationType === 'Communication' && item.communication_id !== 0) {
            theType = 'communication'
            theId = item.communication_id
        } else if (item.notificationType === 'CommunicationDone' && item.communication_id !== 0) {
            theType = 'communication'
            theId = item.communication_id
        } else if (item.notificationType === 'Comment' && item.communication_id !== 0) {
            theType = 'communication'
            theId = item.communication_id
        } else if (item.notificationType === 'Request' && item.communication_id !== 0) {
            theType = 'adminRequest'
            theId = item.communication_id
        }
        try {
            if (theType == 'adminRequest') {
                router.push(`/request`)
                handleRead(item)
                return
            }
            if (theType && theId > 0) {
                type.value = theType
                await nextTick()
                switchToRead(theId)
                handleRead(item)
            }
        } catch (error) {
            console.log(error)
        }
    }

    // All notifications that are clickable
    const clickableNofifications = computed(() => {
        return notifications.value?.filter((notification) => {
            return (
                notification.notificationType == 'Request' ||
                task.value.some((task) => {
                    return task.id === notification.task_id
                }) ||
                communications.value.some((comm) => {
                    return comm.id === notification.communication_id || comm.id === notification.communicationcomments_id
                })
            )
            return
        })
    })

    import { useCalendarStore } from '@stores/calendarStore'
    import { watch } from 'vue'
    const calStore = useCalendarStore()
    const { users } = storeToRefs(calStore)

    function userShortCode(name: string) {
        const user = users.value.find((user) => user.name === name)
        return user?.shortcode
    }

    //FIXME : SY the Backend gave all text-translations that could not be mapped to the right tranlations. Therefore I have filtered the notificartion keys.
    const computedNotifications = computed(() => {
        return clickableNofifications.value
            ?.filter((n) => {
                return (
                    n.notificationText === 'notification.standard.text.comment' ||
                    n.notificationText === 'notification.standard.text.communication' ||
                    n.notificationText === 'notification.standard.text.communicationdone' ||
                    n.notificationText === 'notification.standard.text.request' ||
                    n.notificationText === 'notification.standard.text.task' ||
                    n.notificationText === 'notification.standard.text.request'
                )
            })
            .map((n) => {
                let typeKey = ''
                switch (n.notificationText) {
                    case 'notification.standard.text.comment':
                        typeKey = 'far fa-comment'
                        break
                    case 'notification.standard.text.communication':
                        typeKey = 'far fa-calendar'
                        break
                    case 'notification.standard.text.communicationdone':
                        typeKey = 'far fa-badge-check'
                        break
                    case 'notification.standard.text.request':
                        typeKey = 'far fa-messages-question'
                        break
                    case 'notification.standard.text.task':
                        typeKey = 'far fa-clipboard-list'
                        break
                    default:
                        typeKey = 'unknown'
                        break
                }
                return { ...n, typeKey }
            })
            .slice()
    })

    const unreadNotificationsCount = computed(() => {
        return computedNotifications.value?.filter((item) => item?.readAt === null)
    })

    const readNotificationsCount = computed(() => {
        return computedNotifications.value?.filter((item) => item?.readAt !== null)
    })

    const showNotifications = ref(false)

    function toggleNewNotification() {
        showNotifications.value = false
    }

    function toggleReadedNotification() {
        showNotifications.value = true
    }
    function getCreatedTime(date: number | null) {
        if (date === null) {
            return 'Invalid date'
        }
        return dateUtils.getDateTime(date)
    }

    watch(
        () => authStore.notificationPanel.show,
        (newValue) => {
            if (newValue === true) {
                showNotifications.value = false
            }
        }
    )
</script>

<template>
    <div :class="{ 'slid-up': showNotifications }" class="p-0 w-100">
        <div class="btns-container">
            <v-btn class="btn-new" variant="text" @click="toggleNewNotification">
                <v-card variant="plain">
                    <v-card-item class="p-0">
                        <v-card-title>
                            <v-icon icon="fa:far fa-bell" class="mx-4"></v-icon>
                            <label class="text-h6">{{ t('notification.new') }} ({{ unreadNotificationsCount?.length }})</label></v-card-title
                        >
                    </v-card-item>
                </v-card>
            </v-btn>
            <v-btn class="btn-read" variant="text" @click="toggleReadedNotification">
                <v-card variant="plain">
                    <v-card-item class="p-0">
                        <v-card-title>
                            <v-icon icon="fa:far fa-eye-slash" class="mx-4"></v-icon>
                            <label class="text-h6">{{ t('notification.readed') }} ({{ readNotificationsCount?.length }})</label></v-card-title
                        >
                    </v-card-item>
                </v-card>
            </v-btn>
        </div>
        <transition name="slide">
            <v-container v-if="showNotifications" class="notification-container">
                <v-btn @click="openNotification(item)" variant="tonal" class="notification-div-opacity" v-for="item in readNotificationsCount">
                    <s class="btn-desc">
                        <div class="header">
                            <i :class="item.typeKey"></i>
                            <p class="description" v-if="item.notificationText">{{ t(item.notificationText) }}</p>
                        </div>
                        <div class="body">
                            <p class="user-tag">{{ userShortCode(item.createdByName ?? '') }}</p>
                            {{ getCreatedTime(item.created_at) }}
                        </div>
                    </s>
                </v-btn>
            </v-container>
        </transition>
        <v-container v-if="!showNotifications" class="notification-container">
            <v-btn @click="handleReadAll" class="readAll-btn" variant="tonal" prepend-icon="fa-regular fa-flag">{{ t('notification.standard.readAll') }}</v-btn>
            <div class="no-notification-container" v-if="!unreadNotificationsCount?.length">
                <p class="float-right">
                    <i class="fa-solid fa-thumbs-up pr-1 text-h6"></i>
                    {{ t('notification.standard.empty') }}
                </p>
            </div>
            <v-btn @click="openNotification(item)" variant="tonal" class="notification-div" v-for="item in unreadNotificationsCount">
                <div class="btn-desc">
                    <div class="header">
                        <i :class="item.typeKey"></i>
                        <p class="description" v-if="item.notificationText">{{ t(item.notificationText) }}</p>
                    </div>
                    <div class="body">
                        <p v-if="item.createdByName" class="user-tag">
                            {{ userShortCode(item.createdByName ?? '') }}
                            <v-tooltip activator="parent" theme="light" location="top"> {{ t('notification.createdBy') }}: {{ item.createdByName }}</v-tooltip>
                        </p>
                        {{ getCreatedTime(item.created_at) }}
                    </div>
                </div>
            </v-btn>
        </v-container>
    </div>
</template>

<style lang="scss" scoped>
    :deep(.v-navigation-drawer__content) {
        padding: 0 !important;
        width: 100% !important;
        flex: auto !important;
    }
    .no-notification-container {
        width: fit-content;
        margin: auto;
        justify-self: center;
        text-align: center;
        opacity: 0.8;
        margin-top: 2rem;
    }
    .btn-desc {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100% !important;
        height: auto;
        .header {
            display: flex;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 0.5rem;
            i {
                font-size: 20px;
                color: rgba(255, 255, 255, 0.8);
            }
            .description {
                display: flex;
                font-size: 12px;
                padding-left: 0.5rem;
                color: rgba(255, 255, 255, 0.8);
                font-style: italic;
                align-items: center;
                justify-content: center;
                text-align: start;
                white-space: normal;
                line-height: 1.3;
            }
        }
        .pre {
            text-align: start;
        }
        .opacity {
            opacity: 0.4 !important;
        }
        .body {
            display: flex;
            margin-top: 0.75rem;
            flex-direction: row;
            flex-wrap: nowrap;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            font-size: 11px;
            color: rgba(255, 255, 255, 0.5);
            .user-tag {
                background-color: rgba(255, 255, 255, 0.4);
                font-size: 0.8rem;
                color: #444;
                display: flex;
                width: 34px;
                height: 18px;
                border-radius: 20px;
                align-items: center;
                justify-content: center;
                text-align: start;
                font-weight: 700;
                font-size: 12px;
                white-space: normal;
                word-wrap: break-word;
            }
            div {
                margin: 0 0 0 auto;
                font-size: 10px;
                font-weight: 700;
                color: rgba(255, 255, 255, 0.6);
            }
        }
    }
    .btns-container {
        display: flex;
        width: 100% !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.7);
        .btn-new {
            background: none;
            padding: 0 !important;
            width: 85%;
            display: flex !important;
            justify-content: flex-start !important;
            border-right: 1px solid rgba(255, 255, 255, 0.7);
            background-color: #37474f !important;
            color: rgba(255, 255, 255, 0.8);
            i {
                font-size: 16px;
            }
        }

        .btn-read {
            background: none;
            padding: 0 !important;
            width: 85%;
            display: flex !important;
            justify-content: flex-start !important;
            background-color: #223036 !important;
            color: rgba(255, 255, 255, 0.8);
            z-index: 20;
            i {
                font-size: 15px;
            }
        }
    }
    .slid-up .btn-read {
        transform: translateX(-81%);
        border-left: 1px solid rgb(255, 255, 255);
        background-color: #37474f !important;
    }
    .slid-up .btn-new {
        background-color: #223036 !important;
        border: none;
    }
    .notification-div {
        margin-bottom: 0.7rem;
        display: flex;
        flex-direction: column;
        height: auto !important;
        align-items: flex-start;
        width: 100% !important;
        overflow-x: hidden;
        cursor: pointer;
        padding: 0.5rem 1rem;

        p,
        s {
            color: rgb(224, 224, 224);
        }

        p:hover,
        s:hover {
            color: white;
        }
    }
    :deep(.v-btn__content) {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
    .notification-div-opacity {
        margin-bottom: 0.7rem;
        display: flex;
        flex-direction: column;
        height: auto !important;
        align-items: flex-start;
        width: 100% !important;
        overflow-x: hidden;
        cursor: pointer;
        padding: 0.5rem 1rem;
        opacity: 0.6;
    }
    .notification-div-opacity:hover {
        opacity: 1;
    }
    .readAll-btn {
        margin-bottom: 1.5rem;
        color: rgba(255, 255, 255, 0.8);
        font-size: 0.9rem;
        width: 100%;
    }
</style>
