<script setup lang="ts">
    import { watch, ref, inject, provide, onMounted, onBeforeMount, computed } from 'vue'
    import { storeToRefs } from 'pinia'
    import { useRoute } from 'vue-router'
    import { apiCall } from '@/api/apiCall'
    import MainMenu from '@components/MainMenu.vue'
    import FooterComponent from '@components/FooterComponent.vue'
    import { useOnline } from '@vueuse/core'
    import { useToast, POSITION } from 'vue-toastification'
    import ToastAlerts from '@/components/TheToastAlerts.vue'
    import TheCrudDrawer from './components/TheCrudDrawer.vue'
    import TheLoadingScreen from './components/TheLoadingScreen.vue'
    import NotificationPanel from '@components/notification/NotificationPanel.vue'

    //NOTE - setGlobalt is a method that sets up a const 'globalt' to use the t method inside modules. Needs to be initialized in a setup function.
    import { setGlobalt } from './assets/i18n/globalt'
    import i18nCDN from '@/utilities/i18nCDN'
    import { useAuthStore } from '@stores/authStore'
    import { useGlobalRouter } from '@/router/globalRouterInstance.ts'
    import { useRouter } from 'vue-router'

    declare function nolt(...args: any[]): void

    import { useI18n } from 'vue-i18n'
    const { t } = useI18n()

    const authStore = useAuthStore()
    setGlobalt()

    const { gRouter } = useGlobalRouter()
    const router = useRouter()
    gRouter.value = router

    /*
     * NOTE - Setup Hintwidget and ctrl/shift keypresses app wide.
     * This globally declares the state of the ctrl and shift keys, and makes them available through the useHintWidget() function.
     */
    import { ctrl, shift } from '@/utilities/shortcuts/shortCutMap'
    import { useHintWidget } from '@/utilities/shortcuts/hintWidget'

    const toast = useToast()
    const online = useOnline()

    // function to check the internet connectivity
    watch(
        online,
        (val) => {
            if (!val) {
                // Display the toast and store the ID
                toast.warning(`${t('generalKeys.internetFailed')}`, {
                    timeout: false,
                    position: POSITION.TOP_RIGHT,
                })
            } else {
                toast.clear()
            }
        },
        { immediate: true }
    )

    const globalCtrl = ctrl()
    const globalShift = shift()
    const dialogCtrl = ctrl(() => {}, 'dialog')
    const drawerCtrl = ctrl(() => {}, 'drawer')
    const eitherCtrl = ctrl(() => {}, 'either')
    const neitherCtrl = ctrl(() => {}, 'neither')
    const hintWidget = useHintWidget()

    watch(
        [
            () => globalCtrl.isPressed.value,
            () => globalShift.isPressed.value,
            () => dialogCtrl.isPressed.value,
            () => drawerCtrl.isPressed.value,
            () => eitherCtrl.isPressed.value,
            () => neitherCtrl.isPressed.value,
        ],
        ([global, shift, dialog, drawer, either, neither]) => {
            hintWidget.globalCtrlIsPressed.value = global
            hintWidget.globalShiftIsPressed.value = shift
            hintWidget.dialogCtrlIsPressed.value = dialog
            hintWidget.drawerCtrlIsPressed.value = drawer
            hintWidget.eitherDialogDrawerCtrlIsPressed.value = either
            hintWidget.neitherDialogDrawerCtrlIsPressed.value = neither
        }
    )
    // END Setup Hintwidget appwide

    const FKconfig = inject<{ locale: string }>(Symbol.for('FormKitConfig'))

    onMounted(async () => {
        const metaJSON = (await apiCall('/localazy', 'get', null, null)) as unknown as string
        let metaFile: any
        if (metaJSON) {
            const res = await fetch(metaJSON)
            metaFile = await res.json()
        }
        await i18nCDN.getLocaleFromCDN('nl', metaFile ? metaFile : null)
        await i18nCDN.getLocaleFromCDN('en', metaFile ? metaFile : null)
        await i18nCDN.getLocaleFromCDN('fr', metaFile ? metaFile : null)
        await i18nCDN.getLocaleFromCDN('de', metaFile ? metaFile : null)
        authStore.FKconfig = FKconfig
        //NOTE - Set formkit locale to equal global locale. Required to prevent bugs with router guards.
        if (authStore.FKconfig) {
            authStore.FKconfig.locale = authStore.locale
        }
    })

    // check the window width and add the viewport meta tag
    onBeforeMount(async () => {
        if (window.innerWidth < 600) {
            const meta = document.createElement('meta')
            meta.name = 'viewport'
            meta.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no'
            document.head.appendChild(meta)
        }
    })

    const { isLoggedIn } = storeToRefs(authStore)

    // hide all pre tags in production
    const env = import.meta.env.VITE_ENVIRONMENT || 'dev'
    if (env === 'prod') {
        document.body.classList.add('nopre')
    }

    const showCommDrawer = ref(true)
    provide('showCommDrawer', showCommDrawer)

    const route = useRoute()

    const isNoltRoute = computed(() => route.path === '/nolt')
</script>

<template>
    <the-loading-screen>
        <v-app class="ck" style="position: relative">
            <main-menu v-if="isLoggedIn && !isNoltRoute" />
            <the-crud-drawer v-if="isLoggedIn && !isNoltRoute" />
            <notification-panel v-if="isLoggedIn && !isNoltRoute" />
            <div id="content-guides"></div>
            <router-view />
            <footer-component v-if="isLoggedIn && !isNoltRoute" />
            <toast-alerts />
        </v-app>
    </the-loading-screen>
</template>

<style lang="scss" scoped>
    @media print {
        @page {
            size: landscape;
        }
        body * {
            display: block;
            :deep(main) {
                display: block;
                padding: 0;
                width: 100vw !important;
            }
        }
        .online {
            position: absolute;
            bottom: 100px;
            right: 100px;
        }
    }
</style>
