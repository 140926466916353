import { ref } from 'vue'
import { type Router } from 'vue-router'

const gRouter = ref<Router>()

export const useGlobalRouter = () => {
    return { gRouter }
}

export default useGlobalRouter
